/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneSearch as ResolvedStandaloneSearch } from 'base/components/standalone/Search';

export const Search = ResolvedStandaloneSearch;

export type SearchProps = PropsFromComponent<typeof Search>;

export const SearchWith = (extras: DynamicStandaloneExtras): typeof Search => {
    return function Search(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Search');
        return <ResolvedStandaloneSearch {...mergeProps({ options: { theme } }, props)} />;
    }
}