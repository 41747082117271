import { OptionalLink } from '@/components/OptionalLink';
import { componentClassName } from '@/styles/ArticleTeaserDefault';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { withSafeInnerHTML } from 'lib/utils';
import mergeProps from 'lib/utils/mergeProps';
import { useArticleTeaserContext } from './ArticleTeaser';

export interface ArticleTeaserDescriptionProps extends ComponentProps<'div'> {
  link?: ComponentProps<'a'>;
  size?: ClassNameProp<'horizontal' | 'vertical'>;
  variant?: ClassNameProp<'horizontal' | 'vertical'>;
}

export const ArticleTeaserDescriptionComponent: Component<ArticleTeaserDescriptionProps> = ({
  children,
  colors,
  link,
  size,
  variant,
  ...props
}) => {
  const { theme, ...contextClassNameProps } = useArticleTeaserContext({ colors, size, variant });

  const className = componentClassName('description', contextClassNameProps, {
    defaults: { size: 'vertical', variant: 'vertical' },
    theme,
  });

  const isWithLink = Boolean(link?.href);

  return (
    <div {...(!isWithLink && withSafeInnerHTML(children))} {...mergeProps({ className }, props)}>
      {isWithLink ? <OptionalLink {...link} content={children} /> : undefined}
    </div>
  );
};
