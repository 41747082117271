import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ContentProps } from '@/types/content';
import mergeProps from 'lib/utils/mergeProps';
import type { ReactNode } from 'react';
import { ArticleTeaser, ArticleTeaserProvider } from './ArticleTeaser';
import { ArticleTeaserImageProps } from './ArticleTeaser.Image';

export const getCategoryUrl = (data: ContentProps['data'], meta: ContentProps['meta']) => {
  const { category, categoryUrl, prominentTag } = data ?? {};
  const { isNativeAd, nativeAdLabel } = meta ?? {};

  if (isNativeAd) {
    return `etikett/${(nativeAdLabel || ('' as string)).toLocaleLowerCase()}`;
  }

  if (prominentTag) {
    return `etikett/${prominentTag}`;
  }

  return categoryUrl || category;
};

export interface StandaloneArticleTeaserDefaultProps extends StandaloneComponentProps<typeof ArticleTeaser> {
  caption?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  headline?: ReactNode;
  kicker?: ReactNode;
  links?: {
    article?: OptionalLinkProps;
    caption?: OptionalLinkProps;
  };
  image: ArticleTeaserImageProps;
}

export const StandaloneArticleTeaserDefault: StandaloneComponent<StandaloneArticleTeaserDefaultProps> = ({
  caption,
  description,
  footer,
  header,
  headline,
  image,
  kicker,
  links,
  options,
  ...props
}) => {
  const { $caption, $description, $footer, $group, $header, $headline, $image, ...$base } = options ?? {};
  const { colors, size, variant, theme } = $base ?? {};

  return (
    <ArticleTeaserProvider value={{ colors, size: size ?? variant, variant, theme }}>
      <ArticleTeaser {...$base} {...props}>
        {header && <ArticleTeaser.Header {...$header}>{header}</ArticleTeaser.Header>}
        <OptionalLink
          {...links?.article}
          content={<ArticleTeaser.Image {...mergeProps({ options: $image }, image)} />}
        />
        <ArticleTeaser.Group {...$group}>
          {caption && (
            <OptionalLink
              {...links?.caption}
              content={<ArticleTeaser.Caption {...$caption}>{caption}</ArticleTeaser.Caption>}
            />
          )}
          {headline && (
            <OptionalLink
              {...links?.article}
              content={<ArticleTeaser.Headline {...$headline}>{headline}</ArticleTeaser.Headline>}
            />
          )}
          {description && <ArticleTeaser.Description {...$description}>{description}</ArticleTeaser.Description>}
          {kicker && <ArticleTeaser.Description {...$description}>{kicker}</ArticleTeaser.Description>}
        </ArticleTeaser.Group>
        {footer && <ArticleTeaser.Footer {...$footer}>{footer}</ArticleTeaser.Footer>}
      </ArticleTeaser>
    </ArticleTeaserProvider>
  );
};
