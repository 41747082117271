import c from '@/styles/Row';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { RowButtonComponent } from './Row.Button';
import { RowCaptionComponent } from './Row.Caption';
import { RowFooterComponent } from './Row.Footer';
import { RowHeadlineComponent } from './Row.Headline';

export interface RowProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default'>;
}

const RowComponent: Component<RowProps> = ({ children, className, colors, size, variant, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('colors', colors, 'default');
  const sizeClassName = getPropStyles('size', size, 'default');
  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const Row = Object.assign(RowComponent, {
  Button: RowButtonComponent,
  Caption: RowCaptionComponent,
  Footer: RowFooterComponent,
  Headline: RowHeadlineComponent,
});
