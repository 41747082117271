import { componentClassName } from '@/styles/ArticleTeaserDefault';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { useArticleTeaserContext } from './ArticleTeaser';

export interface ArticleTeaserHeaderProps extends ComponentProps<'div'> {
  size?: ClassNameProp<'horizontal' | 'vertical'>;
  variant?: ClassNameProp<'horizontal' | 'vertical'>;
}

export const ArticleTeaserHeaderComponent: Component<ArticleTeaserHeaderProps> = ({
  children,
  colors,
  size,
  variant,
  ...props
}) => {
  const { theme, ...contextClassNameProps } = useArticleTeaserContext({ colors, size, variant });

  const className = componentClassName('header', contextClassNameProps, {
    defaults: { size: 'vertical', variant: 'vertical' },
    theme,
  });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
