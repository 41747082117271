/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneRow as ResolvedStandaloneRow } from 'base/components/standalone/Row';

export const Row = ResolvedStandaloneRow;

export type RowProps = PropsFromComponent<typeof Row>;

export const RowWith = (extras: DynamicStandaloneExtras): typeof Row => {
    return function Row(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Row');
        return <ResolvedStandaloneRow {...mergeProps({ options: { theme } }, props)} />;
    }
}