import { componentClassName } from '@/styles/ArticleTeaserDefault';
import { ClassNameProp, ClassNameProps, ComponentProps, ComponentWithRef, ThemeProp } from '@/types/component';
import { ComponentContextFactory } from 'lib/contexts/ComponentContextFactory';
import React from 'react';
import { ArticleTeaserCaptionComponent } from './ArticleTeaser.Caption';
import { ArticleTeaserDescriptionComponent } from './ArticleTeaser.Description';
import { ArticleTeaserFooterComponent } from './ArticleTeaser.Footer';
import { ArticleTeaserGroupComponent } from './ArticleTeaser.Group';
import { ArticleTeaserHeaderComponent } from './ArticleTeaser.Header';
import { ArticleTeaserHeadlineComponent } from './ArticleTeaser.Headline';
import { ArticleTeaserImageComponent } from './ArticleTeaser.Image';
import { mergeProps } from '@/utils/merge';

export interface ArticleTeaserProps extends ComponentProps<'article'> {
  size?: ClassNameProp<'horizontal' | 'vertical'>;
  variant?: ClassNameProp<'horizontal' | 'vertical'>;
}

export const {
  Context: ArticleTeaserContext,
  Provider: ArticleTeaserProvider,
  useComponentContext: useArticleTeaserContext,
} = ComponentContextFactory<Pick<ArticleTeaserProps, keyof ClassNameProps | keyof ThemeProp>>();

const ArticleTeaserComponent: ComponentWithRef<HTMLElement, ArticleTeaserProps> = React.forwardRef(
  ({ children, colors, size, variant, ...props }, ref) => {
    const { theme, ...contextClassNameProps } = useArticleTeaserContext({ colors, size, variant });

    const className = componentClassName('', contextClassNameProps, {
      defaults: { size: 'vertical', variant: 'vertical' },
      theme,
    });

    return <article {...mergeProps({ className }, props)}>{children}</article>;
  }
);

export const ArticleTeaser = Object.assign(ArticleTeaserComponent, {
  Caption: ArticleTeaserCaptionComponent,
  Description: ArticleTeaserDescriptionComponent,
  Footer: ArticleTeaserFooterComponent,
  Group: ArticleTeaserGroupComponent,
  Header: ArticleTeaserHeaderComponent,
  Headline: ArticleTeaserHeadlineComponent,
  Image: ArticleTeaserImageComponent,
});
