import c from '@/styles/Row';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface RowHeadlineProps extends ComponentProps<'header'> {
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default' | 'decorated'>;
}

export const RowHeadlineComponent: Component<RowHeadlineProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('headline_colors', colors, 'default');
  const sizeClassName = getPropStyles('headline_size', size, 'default');
  const variantClassName = getPropStyles('headline_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <header className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </header>
  );
};
