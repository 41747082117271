import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Row, RowProps } from './Row';
import { RowButtonProps } from './Row.Button';
import { RowCaptionProps } from './Row.Caption';
import { RowFooterProps } from './Row.Footer';
import { RowHeadlineProps } from './Row.Headline';

export interface StandaloneRowProps extends StandaloneComponentProps {
  button?: Pick<RowButtonProps, 'content' | 'link'>;
  caption?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  headline?: ReactNode;
  options?: RowProps & {
    $button?: RowButtonProps['options'];
    $caption?: RowCaptionProps;
    $footer?: RowFooterProps;
    $headline?: RowHeadlineProps;
  };
}

export const StandaloneRow: StandaloneComponent<StandaloneRowProps> = ({
  button,
  caption,
  content,
  footer,
  headline,
  options,
  ...props
}) => {
  const {
    $button: buttonProps,
    $caption: captionProps,
    $footer: footerProps,
    $headline: headlineProps,
    ...baseProps
  } = options ?? {};

  return (
    <Row {...baseProps} {...props}>
      {headline && <Row.Headline {...headlineProps}>{headline}</Row.Headline>}
      {caption && <Row.Caption {...captionProps}>{caption}</Row.Caption>}
      {content}
      {(footer || button) && (
        <Row.Footer {...footerProps}>
          {footer}
          {button && <Row.Button content={button.content} link={button.link} {...buttonProps} />}
        </Row.Footer>
      )}
    </Row>
  );
};
