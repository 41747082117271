import { Adunit } from '@/components/Adunit';
import { Spinner } from '@/components/Spinner';
import { BylineData, BylineDataResult } from '@/types/bylineData';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { SearchData, SearchResult } from '@/types/search';
import { getDomain } from '@/utils/index';
import { ErrorNode } from 'base/components/ErrorBoundary';
import { getAdUnit, shouldInjectAdAtRow } from 'lib/data/compute/ads/helpers';
import getUrl from 'lib/utils/getUrl';
import logger from 'lib/utils/logger';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { Search, SearchProps } from './Search';
import { SearchItemProps } from './Search.Item';

const AUTHOR_PAGE = 'authorPage';
export const fetchAuthorArticles = async (authorName: string, next?: number) => {
  const url = getUrl('api/byline-name', getDomain());
  if (!url) {
    logger.error('Byline Name: Could not construct URL');
    return {};
  }

  url.searchParams.set('bylineName', authorName);
  url.searchParams.set('start', `${next ?? 0}`);

  try {
    const response = await fetch(url.href);
    if (response.status !== 200) {
      logger.error('Author Data API returned ' + response.status);
      return {};
    }
    return response.json();
  } catch {
    logger.catch('Author Data API: There was an unknown error.');
    return {};
  }
};
export const fetchSearchResults = async (query: string, next?: number, filter?: string) => {
  const host = getDomain();
  const url = getUrl(`/api/search`, host);

  if (!url) {
    logger.error('Search: Could not construct URL');
    return {};
  }

  url?.searchParams.set('term', query);
  url?.searchParams.set('start', `${next ?? 1}`);
  url?.searchParams.set('filter', filter ?? '');

  try {
    const response = await fetch(url.href);
    if (response.status !== 200) {
      logger.error('Search: API returned ' + response.status);
      return {};
    }
    return response.json();
  } catch {
    logger.catch('Search: There was an unknown error.');
    return {};
  }
};

export interface StandaloneSearchProps extends StandaloneComponentProps {
  initial?: {
    results?: SearchResult[] | BylineDataResult[];
    next?: number;
  };
  query?: string;
  adsUniqueId?: string;
  total?: number;
  pageType?: typeof AUTHOR_PAGE | 'searchResults';
  headline?: ReactNode;
  button?: ReactNode;
  options?: SearchProps & {
    $item?: SearchItemProps;
  };
}
const getResults = (
  pageType = 'searchResults',
  query: string,
  next: number,
  callback: () => void,
): Promise<SearchData | BylineData> => {
  if (pageType === AUTHOR_PAGE) {
    return fetchAuthorArticles(query, next).catch((error) => {
      logger.catch(error);
      return callback();
    });
  }
  return fetchSearchResults(query, next).catch((error) => {
    logger.catch(error);
    return callback();
  });
};
export const StandaloneSearch: StandaloneComponent<StandaloneSearchProps> = ({
  initial,
  query,
  adsUniqueId = '',
  total,
  headline,
  button,
  options,
  pageType,
  ...props
}) => {
  const [results, setResults] = useState<SearchResult[]>(initial?.results ?? []);
  const [next, setNext] = useState(initial?.next ?? 1);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { $item: itemProps, ...baseProps } = options ?? {};

  const onLoadMore = useCallback(async () => {
    if (isLoading) return;

    if (!query || !next) {
      setNext(0);
      return logger.error(`Load more search results have failed.`, { query, next });
    }

    setLoading(true);
    const response = await getResults(pageType, query, next, () => setError(true));

    if (!response || response.status !== 'search_successful') {
      logger.error(response);
      return setError(true);
    }

    setNext(response.nextPage || 0);

    setResults([...results, ...response.items]);

    setLoading(false);
  }, [isLoading, next, query, results]);

  const defaultHeadline: SearchProps['headline'] = useMemo(() => {
    if (pageType === AUTHOR_PAGE) {
      return (
        <h1>
          Artiklar av <em>{query || '?'}</em>
        </h1>
      );
    }

    return (
      <h1>
        Sökresultat för <em>{query || '?'}</em>
      </h1>
    );
  }, [query, pageType]);

  let adPlacementNo = 1;

  const caption: SearchProps['caption'] = `Hittade ${total || 0} resultat`;

  const content: SearchProps['content'] = (
    <>
      {results?.map((item, index) => (
        <Fragment key={index}>
          <Search.Item
            headline={item.title}
            caption={item.date}
            description={item.snippet}
            image={{ src: item.image, alt: item.title }}
            links={{ article: { href: item.link } }}
          />
          {shouldInjectAdAtRow({ currentRow: index, frequency: 3 }) && (
            <Adunit data={getAdUnit('responsive', adPlacementNo++, adsUniqueId)?.data} title={'Annons'} />
          )}
        </Fragment>
      ))}
      {isLoading && <Spinner />}
    </>
  );

  if (isError) {
    return ErrorNode;
  }

  const isButtonDisabled = isLoading || isError;

  return (
    <Search
      button={{ content: button ?? 'Ladda mer' }}
      options={{ $button: { className: `${!next ? 'hidden' : ''}`, disabled: isButtonDisabled, onClick: onLoadMore } }}
      headline={headline || defaultHeadline}
      {...{ caption, content }}
      {...baseProps}
      {...props}
    />
  );
};
