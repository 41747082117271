import { Image, ImageProps } from '@/components/Image';
import { componentTheme } from '@/styles/ArticleTeaserDefault';
import { StandaloneComponent } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type ArticleTeaserImageProps = ImageProps;

export const ArticleTeaserImageComponent: StandaloneComponent<ArticleTeaserImageProps> = (props) => {
  return (
    <Image
      {...mergeProps(
        {
          options: {
            fill: true,
            theme: componentTheme('image'),
          },
        },
        props,
      )}
    />
  );
};
