/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "image": {
    "colors_default": "",
    "size_default": "aspect-[40/21] not-[img]:container-col-sm-8:-mx-3 not-[img]:sm:container-col-md-8:-mx-4 md:w-full md:!mx-auto",
    "variant_default": "relative block object-cover"
  },
  "caption_colors_default": "text-gray-600 order-3",
  "caption_size_horizontal": "text-headline-3xs",
  "caption_size_vertical": "text-headline-3xs",
  "caption_variant_horizontal": "break-words capitalize",
  "caption_variant_vertical": "break-words capitalize",
  "description_colors_default": "text-black",
  "description_size_horizontal": "",
  "description_size_vertical": "text-body-sm sm:text-body-md",
  "description_variant_horizontal": "hidden",
  "description_variant_vertical": "hidden",
  "footer_colors_default": "",
  "footer_size_horizontal": "max-w-full",
  "footer_size_vertical": "max-w-full",
  "footer_variant_horizontal": "col-span-full overflow-hidden",
  "footer_variant_vertical": "overflow-hidden",
  "group_colors_default": "",
  "group_size_horizontal": "gap-2",
  "group_size_vertical": "gap-2 sm:container-col-lg-7:gap-3 sm:container-col-lg-7:mt-1",
  "group_variant_horizontal": "flex flex-col min-w-[0]",
  "group_variant_vertical": "flex flex-col min-w-[0]",
  "header_colors_default": "",
  "header_size_horizontal": "max-w-full",
  "header_size_vertical": "max-w-full",
  "header_variant_horizontal": "col-span-2 overflow-hidden",
  "header_variant_vertical": "overflow-hidden",
  "headline_colors_default": "text-black",
  "headline_size_horizontal": "text-headline-xs sm:text-headline-sm sm:container-col-lg-8:text-headline-md",
  "headline_size_vertical": "text-headline-xs container-col-sm-8:text-headline-lg sm:text-headline-md sm:container-col-lg-8:text-headline-2xl",
  "headline_variant_horizontal": "break-words",
  "headline_variant_vertical": "break-words",
  "colors_default": "",
  "size_horizontal": "w-full grid-cols-[1fr_2fr] gap-3",
  "size_vertical": "w-full lg:max-w-col-lg-8 gap-2 auto-rows-min mx-auto",
  "variant_horizontal": "grid container-inline",
  "variant_vertical": "grid container-inline"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ArticleTeaserDefault");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;