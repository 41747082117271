import { componentClassName } from '@/styles/ArticleTeaserDefault';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import mergeProps from 'lib/utils/mergeProps';
import { useArticleTeaserContext } from './ArticleTeaser';

export interface ArticleTeaserHeadlineProps extends ComponentProps<'h2'> {
  size?: ClassNameProp<'horizontal' | 'vertical'>;
  variant?: ClassNameProp<'horizontal' | 'vertical'>;
}

export const ArticleTeaserHeadlineComponent: Component<ArticleTeaserHeadlineProps> = ({
  children,
  colors,
  size,
  variant,
  ...props
}) => {
  const { theme, ...contextClassNameProps } = useArticleTeaserContext({ colors, size, variant });

  const className = componentClassName('headline', contextClassNameProps, {
    defaults: { size: 'vertical', variant: 'vertical' },
    theme,
  });

  return (
    <h2 {...withSafeInnerHTML(children)} {...mergeProps({ className }, props)}>
      {withNonHTMLChildren(children)}
    </h2>
  );
};
