import { Button, ButtonProps } from '@/components/Button';
import { componentTheme } from '@/styles/Row';
import { StandaloneComponent } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type RowButtonProps = ButtonProps;

export const RowButtonComponent: StandaloneComponent<RowButtonProps> = (props) => {
  return (
    <Button
      {...mergeProps(
        {
          options: {
            theme: componentTheme('button'),
          },
        },
        props,
      )}
    />
  );
};
